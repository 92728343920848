import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  authorized: boolean = false;

  constructor() { }

  autenticarUsuario() {
    this.authorized = true;
  }

  desautenticarUsuario() {
    this.authorized = false;
  }

  obterUsuarioAutenticado() {
    return this.authorized;
  }
}
