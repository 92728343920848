import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

import { DashboardRoutes } from './dashboard';
import { LayoutComponent } from './layout/layout.component';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
		path: '',
		component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      ...DashboardRoutes,
      {
        path: 'processos',
        loadChildren: () => import('./modules/processos/processos.module').then(m => m.ProcessosModule),
      },
      {
        path: 'clientes',
        loadChildren: () => import('./modules/clientes/clientes.module').then(m => m.ClientesModule),
      },
      {
        path: 'pericias',
        loadChildren: () => import('./modules/pericias/pericias.module').then(m => m.PericiasModule),
      },
      {
        path: 'audiencias',
        loadChildren: () => import('./modules/audiencias/audiencias.module').then(m => m.AudienciasModule),
      },
      {
        path: 'atendimentos',
        loadChildren: () => import('./modules/atendimentos/atendimentos.module').then(m => m.AtendimentosModule),
      },
      {
        path: 'financeiro',
        loadChildren: () => import('./modules/financeiro/financeiro.module').then(m => m.FinanceiroModule),
      },
      {
        path: 'tarefas',
        loadChildren: () => import('./modules/tarefas/tarefas.module').then(m => m.TarefasModule),
      },
      {
        path: 'configuracoes',
        loadChildren: () => import('./modules/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),
      },
    ]
	},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
