import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading$ = new Subject<boolean>();

  loadingOn() {
    this.isLoading$.next(true);
  }

  loadingOff() {
    this.isLoading$.next(false);
  }
}
